<template>
  <div class="cloud-index">
    <div class="banner" />
    <HeaderNav active="/cloud/index" :fixedHeight="bannerHeight" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "CloudIndex",
  components: {
    HeaderNav,
    Footer,
  },

  data() {
    return {
      // 浏览器窗口宽度
      windowWidth: 0
    }
  },

  created() {
    // 获取浏览器窗口宽度
    this.windowWidth = window.innerWidth
  },
  
  mounted() {
    // 监听浏览器窗口尺寸变化, 得到当前浏览器窗口宽度
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },

  destroyed() {
    // 销毁onsize监听
    window.onresize = null
  },

  computed: {
    // 根据浏览器窗口宽度 * banner宽高比, 得到banner高度
    bannerHeight() {
      const { windowWidth } = this
      return windowWidth >= 1280 ? windowWidth * (534 / 1920) : 1280 * (534 / 1920)
    }
  }
};
</script>

<style lang="less">
.cloud-index {
  width: 100%;
  .banner {
    width: 100%;
    min-width: 1280px;
    padding-bottom: 100% * (534 / 1920);
    // background-image: url(../../assets/cloud/banner.png);
    // background-image: url('https://img.miaocang.cc/pcImg/cloud/banner.png');
    background-image: url('https://img.miaocang.cc/pcImg/cloud/web_banner.png');
    background-size: cover;
  }
}
</style>